<template>
  <div
    class="container-fluid px-0 h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Programmes']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                ">
                <strong>Programmes </strong><br />
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="">
              <input
                type="text"
                v-model.trim="titleSearch"
                @input="onSearch"
                placeholder="Search by Title"
                class="search_by_input border-color-fix"
                spellcheck="off"
              />
            </div>
          </div>
          <hr />

          <div class="table-responsive">
            <data-tables-server
              :data="courses"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalCourses"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="onTableChange"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getProgrammes"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Programmes Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Programmes</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Programme Code"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="150px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.course_code }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Programme Title"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.title }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Publish Status"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="160px"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      v-if="scope.row.is_website_published"
                      style="color: green; font-weight: 400;"
                    ><i class="mr-1 el-icon-circle-check"></i> Published
                    </span>
                    <span
                      v-else
                      style="color: orange; font-weight: 400;"
                    ><i class="el-icon-warning-outline"></i> Not Published
                    </span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="110"
              >
                <template slot-scope="scope">
                  <el-row style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    ">
                    <el-tooltip
                      content="Publish"
                      placement="top"
                    ><span>
                        <el-button
                          class=""
                          type="success
                      "
                          @click="publishProgram(scope.row.course_id)"
                          icon="fas fa-check"
                          circle
                        ></el-button>
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      content="Details"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        class="mr-2 ml-2"
                        @click="goToProgrammeDetails(scope.row.course_id)"
                        icon="el-icon-edit"
                        circle
                      >
                      </el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import debounce from "debounce";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
  },

  data() {
    return {
      loading: false,
      loadingError: false,
      submiting: false,
      courses: [],
      titleSearch: "",

      totalCourses: 0,
      currentPage: 1,
      pageSize: 10,

      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],

      course_id: "",
    };
  },
  mounted() {
    this.getProgrammes();
  },

  methods: {
    onSearch: debounce(function () {
      this.getProgrammes();
    }, 500),

    async onTableChange(queryInfo) {
      if (queryInfo) {
        if (
          queryInfo.type == "page" ||
          queryInfo.type == "size" /* ||
          queryInfo.type == "filter" */
        ) {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getProgrammes();
        }
      }
    },

    async getProgrammes() {
      try {
        //Show Loading state
        this.loading = true;
        this.loadingError = false;

        let response = await this.$http.get(
          `website/courses?page=${this.currentPage}&pageSize=${this.pageSize}&titleSearch=${this.titleSearch}`
        );
        if (
          response.data.success &&
          response.data.message == "COURSES FETCHED SUCCESSFULLY"
        ) {
          this.courses = response.data.courses;
          this.totalCourses = response.data.total_courses;
        } else {
          //Report the Error to an error reporting service
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Load Programmes",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async publishProgram(programmeId) {
      await this.$confirm(
        // eslint-disable-line no-unreachable
        "This will update the Publish status of this Programme. Continue?",
        "Confirm",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        }
      );
      try {
        this.loading = true;
        let request = await this.$http.patch(
          `website/courses/${programmeId}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message == "COURSE PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getProgrammes();
          this.showSuccessMessage(
            "Success",
            "Programme published successfully"
          );
        } else throw "UNEXPECTED ERROR OCCURRED";
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "MISSING REQUIRED FIELDS") {
            return this.showWarningMessage(
              "Missing Required Fields",
              "This program can not be published because there are missing required fields. Please fill all the program information before you can publish it."
            );
          }
        } 
        this.showFailedMessage(
          "Unable to Publish Programme",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    goToProgrammeDetails(programmeId) {
      let programmeName = this.courses.find((course) => {
        return course.course_id == programmeId;
      }).title;
      this.$router.push({
        name: "ProgrammeDetails",
        params: {
          programmeId,
          programmeName,
        },
      });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
.course_request_form .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.course_request_form .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.course_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .course_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
