<template>
  <div v-loading="submitting">
    <h5 class="mb-4">What you will learn</h5>
    <ReUsableTextEditor v-model="whatYouWillLearn"></ReUsableTextEditor>

    <div
      style="display: flex; align-items: center"
      class="mt-4"
    >
      <el-button
        type="primary"
        @click="saveChanges"
      >Save Changes</el-button>
    </div>
  </div>
</template>
<script>
import ReUsableTextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
export default {
  props: {
    programmeId: { type: String, required: true },
    what_you_will_learn_html_path_url: { required: true },
  },
  components: {
    ReUsableTextEditor,
  },
  data() {
    return {
      whatYouWillLearn: "",
      submitting: false,
    };
  },

  watch: {
    what_you_will_learn_html_path_url: {
      handler() {
        this.whatYouWillLearnText();
      },
    },
  },

  methods: {
    async whatYouWillLearnText() {
      try {
        if (this.what_you_will_learn_html_path_url === null) {
          return false;
        } else {
          this.submitting = true;
          let request = await this.$http.get(
            `${this.what_you_will_learn_html_path_url}`,
            {
              headers: {
                Authorization: "",
                contentType: "text/html",
              },
            }
          );
          this.whatYouWillLearn = request.data;
        }
      } catch (error) {
        if (error.message == "NetworkError") {
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async saveChanges() {
      try {
        this.submitting = true;
        let request = await this.$http.post(
          `website/request-for-html-content-upload-url`,
          {
            reason: "COURSE_DETAILS_HTML",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED"
        ) {
          const aws_upload_url = request.data.upload_url;
          this.uploadResourceToAWS(aws_upload_url, this.whatYouWillLearn);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.submitting = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        if (error.response) {
          if (
            error.response.data.message ===
            "FILE SIZE SHOULD NOT BE MORE THAN 5MB"
          ) {
            return this.showWarningMessage(
              "File too Big",
              "The file can not be more than 5Mbs"
            );
          }
        }
        this.submitting = false;
        this.showFailedMessage(
          "Upload Requirements",
          "Unable to Upload Requirements now, Please try again"
        );
      } finally {
        // this.submitting = false;
      }
    },

    // ___________________________________________________ uploading files
    async uploadResourceToAWS(uploadUrls, selected_file) {
      this.submitting = true;
      try {
        for (var index = 0; index < uploadUrls.length; index++) {
          let url = uploadUrls[index].url;
          let fields = uploadUrls[index].fields;
          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", selected_file);
          this.submitting = true;
          console.log(selected_file);
          let request = await this.$http.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: " ",
            },
            onUploadProgress: (progressEvent) => console.log(progressEvent),
          });

          if (request.status == 204) {
            this.onAWSResourceUploaded(request.headers.location);
          } else throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.submitting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.submitting = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async onAWSResourceUploaded(what_you_will_learn_html_path_url) {
      try {
        this.submitting = true;
        let request = await this.$http.patch(
          `website/courses/update-details/${this.programmeId}`,
          {
            what_you_will_learn_html_path_url: decodeURIComponent(
              what_you_will_learn_html_path_url
            ),
          }
        );
        if (
          request.data.success &&
          request.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
        ) {
          this.showSuccessMessage(
            "Changes saved",
            "The changes were successfully saved"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.response.data.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style scoped></style>
