<template>
  <div v-loading="submitting">
    <h5 class="mb-4">Description</h5>
    <div class="img w-100 position-relative">
      <div class="edit">
        <el-tooltip
          content="Edit Image"
          placement="top"
        >
          <el-button
            type="primary"
            class="mr-2 ml-2"
            @click="openEditProgramDescriptionImage"
            icon="el-icon-edit"
            circle
          >
          </el-button>
        </el-tooltip>
        <!-- <i
          class="el-icon-edit p-3"
          @click="openEditProgramDescriptionImage"
        ></i> -->
      </div>
      <el-image
        fit="cover"
        :src="image_description_url"
        class="w-100 p-0 h-100"
      >
        <div
          slot="placeholder"
          class="image-slot border h-100"
        >
          <div class="w-100 img-placeholder h-100"></div>
        </div>
        <div
          slot="error"
          class="image-slot border h-100"
        >
          <div class="w-100 img-placeholder h-100"></div>
        </div>
      </el-image>
    </div>
    <div class="course-desc mt-4">
      <el-form
        :model="descriptionForm"
        label-position="top"
        ref="descriptionForm"
        class="demo-descriptionForm"
        onSubmit="return false;"
      >
        <div class="row">
          <div class="col-md-12">
            <label for="description">Description</label>
            <el-form-item prop="description">
              <el-input
                id="description"
                type="textarea"
                @keyup.enter.native="saveChanges('descriptionForm')"
                :autosize="{ minRows: 3, maxRows: 24 }"
                placeholder="Please Write the Course Description here"
                v-model="descriptionForm.description"
                maxlength="5000"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <label for="startDate">Start Date</label>
            <el-form-item>
              <el-date-picker
                :format="'dd MMMM yyyy'"
                :value-format="'yyyy-MM-dd'"
                v-model="descriptionForm.startDate"
                id="startDate"
                @keyup.enter.native="saveChanges('descriptionForm')"
                type="date"
                placeholder="Pick a day"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div> -->

        <el-form-item class="">
          <el-button
            type="primary"
            @click="saveChanges('descriptionForm')"
          >Save Changes</el-button>
        </el-form-item>
      </el-form>
    </div>

    <EditProgramDescriptionImageDialog
      :is-visible="showEditProgramDescriptionImageDialog"
      :programmeId="programmeId"
      @fetchSingleProgramme="refreshProgramme"
      @on-close-dialog="closeEditProgramDescriptionImageDialog"
    />
  </div>
</template>
<script>
import EditProgramDescriptionImageDialog from "../dialogs/edit-program-description-image-dialog.vue";
export default {
  props: {
    programmeId: { type: String, required: true },
    image_description_url: {
      required: true,
    },
    course_description: {
      required: true,
    },
    course_start_date: {
      required: true,
    },
  },
  components: { EditProgramDescriptionImageDialog },
  data() {
    return {
      isUploadingFile: false,
      submitting: false,
      isLoading: false,
      showEditProgramDescriptionImageDialog: false,
      isValidated: false,

      descriptionForm: {
        startDate: undefined,
        description: undefined,
      },
      // descriptionRules: {
      //   startDate: [
      //     {
      //       required: true,
      //       message: "Start Date is required",
      //       trigger: "change",
      //     },
      //   ],
      //   description: [
      //     {
      //       required: true,
      //       message: "Description is required",
      //       trigger: "blur",
      //     },
      //   ],
      // },
    };
  },
  watch: {
    course_description: {
      handler() {
        this.descriptionForm.description = this.course_description;
      },
    },
    course_start_date: {
      handler() {
        this.descriptionForm.startDate = this.course_start_date;
      },
    },
  },
  methods: {
    refreshProgramme() {
      this.$emit("fetchSingleProgramme");
    },
    getProgramDescription() {
      alert("Edit IMG");
    },
    openEditProgramDescriptionImage() {
      this.showEditProgramDescriptionImageDialog = true;
    },

    closeEditProgramDescriptionImageDialog() {
      this.showEditProgramDescriptionImageDialog = false;
    },

    async saveChanges(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.patch(
              `website/courses/update-details/${this.programmeId}`,
              {
                image_path_url: this.image_description_url,
                // start_date: this.descriptionForm.startDate,
                description: this.descriptionForm.description,
              }
            );
            if (
              response.data.success &&
              response.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
            ) {
              this.$emit("fetchSingleProgramme");
              return this.showSuccessMessage(
                "Changes saved",
                "The changes were successfully saved"
              );
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            this.loading = true;
            if (error.response.data.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occured, please try again"
              );
            }
            this.showFailedMessage(
              "Unable to Save Changes",
              "An unexpected Error occurred, please try again"
            );
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.img {
  height: 25rem;
}
.img-placeholder {
  background-color: #ebeef4;
  padding: 0 auto;
}
.edit {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 18px;
  z-index: 1;
}
.edit:hover {
  background: none;
  color: #0077b5;
}
</style>
