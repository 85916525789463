<template>
  <div>
    <section>
      <div class="container">
        <div class="image-dialog">
          <el-dialog title="Edit Description Image"
            :visible="isVisible"
            :destroy-on-close="true"
            :before-close="handleClose">
            <div>
              <el-form label-position="top"
                onSubmit="return false;">
                <div class="row">
                  <div class="col-md-12">
                    <label for="file"
                      style="
                            width: 100%;
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.8);
                          ">
                      <strong>Choose File</strong>
                    </label>
                    <div class="mt-3"
                      style="display: flex; justify-content: center">
                      <el-upload class="upload-demo"
                        drag
                        :disabled="isUploadingFile"
                        :action="''"
                        :file-list="fileLists"
                        :on-remove="handleRemove"
                        :on-change="handlePreview"
                        :auto-upload="false"
                        :multiple="false">
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else
                          style="margin-top: 30px">
                          <el-progress v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"></el-progress>
                          <el-progress v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                              imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                            }}
                          </div>
                        </div>
                      </el-upload>
                    </div>
                  </div>
                </div>

                <el-form-item class="text-center mt-5">
                  <ScaleOut v-if="isUploadingFile" />
                  <el-button v-else
                    type="primary"
                    @click="onSelectImage">Edit Image</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
export default {
  components: { ScaleOut },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    programmeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isUploadingFile: false,
      selectedFile: null,
      fileLists: [],
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("on-close-dialog");
      this.fileLists = [];
      this.selectedFile = null;
    },

    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },

    handleRemove() {
      this.selectedFile = null;
    },

    onSelectImage() {
      if (!this.selectedFile) {
        return this.showWarningMessage("No Image Selected", "Select an Image");
      }
      //Ensure it is not more than 2Mbs
      if (this.selectedFile?.size > 2 * 1024 * 1024) {
        return this.showWarningMessage(
          "File Too Large",
          "The file size should not be more than 2MB"
        );
        
      }
      this.requestForSelectedImageUploadUrl(this.selectedFile);
    },

    async requestForSelectedImageUploadUrl(selectedFile) {
      if (selectedFile == null) {
        return this.showWarningMessage(
          "No File Selected",
          "Description Image Missing"
        );
      }

      try {
        this.isUploadingFile = true;
        let request = await this.$http.post(
          `website/request-for-file-upload-url`,
          {
            file_name: selectedFile.raw.name,
            file_size: selectedFile.raw.size,
            reason: "COURSE_DETAILS_IMAGE",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
        ) {
          const aws_upload_url = request.data.upload_url;
          this.uploadResourceToAWS(
            aws_upload_url,
            selectedFile.raw,
            (progressEvent) => {
              this.imageUploadingProgress = +(
                (progressEvent.loaded / progressEvent.total) *
                100
              ).toFixed(0);
            }
          );

          this.fileLists = [];
          this.selectedFile = null;
          //We can now set the uploaded statuses of all the files to 100 and proceed with the edit
          this.imageUploadingProgress = 0;
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.isUploadingFile = false;
        if (error.message == "NetworkError") {
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }

        if (error.response) {
          if (error.response.data.message === "FILE SIZE SHOULD NOT BE MORE THAN 10MB") {
            return this.showWarningMessage(
              "File too big",
              "The file should not be more than 10MB"
            );
          }
        }

        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Description Photo now, Please try again"
        );
      } finally {
        // this.isUploadingFile = false;
        this.imageUploadingProgress = 0;
      }
    },

    // ___________________________________________________ uploading files
    async uploadResourceToAWS(aws_upload_url, selected_file, onProgress) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        console.log(selected_file);
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: onProgress,
        });

        if (request.status == 204) {
          this.saveChanges(request.headers.location);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        // this.isUploadingFile = false;
      }
    },

    async saveChanges(imageURL) {
      try {
        this.isUploadingFile = true;
        let response = await this.$http.patch(
          `website/courses/update-details/${this.programmeId}`,
          {
            image_path_url: imageURL,
          }
        );
        if (
          response.data.success &&
          response.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
        ) {
          this.$emit("fetchSingleProgramme");
          this.$emit("on-close-dialog");
          return this.showSuccessMessage(
            "Changes saved",
            "The changes were successfully saved"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.response.data.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.image-dialog .el-dialog {
  width: 40%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media screen and (max-width: 500px) {

  .image-dialog .el-upload-dragger,
  .image-dialog .el-upload__tip {
    width: 250px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .image-dialog .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .image-dialog .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .image-dialog .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .image-dialog .el-dialog {
    width: 90%;
  }
}
</style>
