import { render, staticRenderFns } from "./tuition-and-fees-component.vue?vue&type=template&id=438e1c5e&scoped=true&"
import script from "./tuition-and-fees-component.vue?vue&type=script&lang=js&"
export * from "./tuition-and-fees-component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438e1c5e",
  null
  
)

export default component.exports