<template>
  <div v-loading="submitting">
    <h5 class="mb-4">Tuition & Fees</h5>
    <div class="d-flex justify-content-center w-100">
      <el-upload
        class="upload-demo"
        drag
        :action="''"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="handlePreview"
        :auto-upload="false"
        :multiple="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <!-- <div class="el-upload__tip" slot="tip">
    jpg/png files with a size less than 500kb
  </div> -->
      </el-upload>
    </div>

    <div
      style="display: flex; align-items: center"
      class="mt-4"
    >
      <el-button
        type="primary"
        @click="saveChanges"
      >Save Changes</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    programmeId: { type: String, required: true },
    tuition_and_fees_attachment_path_url: { required: true },
  },
  data() {
    return {
      submitting: false,
      tuitionAndFees: "",
      selectedFile: null,
      tuitionAndFeesAttachmentFilePathURL: "",
      fileList: [],
    };
  },
  watch: {
    tuition_and_fees_attachment_path_url: {
      handler() {
        if (this.tuition_and_fees_attachment_path_url !== null) {
          this.tuitionAndFeesAttachmentFilePathURL =
            this.tuition_and_fees_attachment_path_url;
          this.fileList = [
            {
              name: "tuition_and_fees_attachment document",
              url: "this.tuition_and_fees_attachment_path_url",
            },
          ];
          this.selectedFile = this.tuition_and_fees_attachment_path_url;
        }
      },
    },
  },
  methods: {
    handlePreview(file) {
      this.fileList = [file];
      this.selectedFile = file;
    },
    handleRemove() {
      this.selectedFile = null;
    },
    // request For Upload Url
    async saveChanges() {
      if (!this.selectedFile) {
        return this.showWarningMessage(
          "No File Selected",
          "Tuition and Fees file Missing"
        );
      }

      try {
        this.submitting = true;
        let request = await this.$http.post(
          `website/request-for-file-upload-url`,
          {
            file_name: this.selectedFile.raw.name,
            file_size: this.selectedFile.raw.size,
            reason: "COURSE_DETAILS_DOCUMENTS",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
        ) {
          const aws_upload_url = request.data.upload_url;
          this.uploadResourceToAWS(aws_upload_url, this.selectedFile.raw);
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.submitting = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.submitting = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Description Photo now, Please try again"
        );
      } finally {
        // this.submitting = false;
      }
    },

    // ___________________________________________________ uploading files
    async uploadResourceToAWS(aws_upload_url, selected_file) {
      this.submitting = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.submitting = true;
        console.log(selected_file);
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          this.onAWSResourceUploaded(request.headers.location);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.submitting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.submitting = false;
        this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Resource Now, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async onAWSResourceUploaded(tuition_and_fees_file_path_url) {
      try {
        this.submitting = true;
        let response = await this.$http.patch(
          `website/courses/update-details/${this.programmeId}`,
          {
            tuition_and_fees_attachment_path_url: decodeURIComponent(
              tuition_and_fees_file_path_url
            ),
          }
        );
        if (
          response.data.success &&
          response.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
        ) {
          this.showSuccessMessage(
            "Changes saved",
            "The changes were successfully saved"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.response.data.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style scoped></style>
