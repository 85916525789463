<template>
  <div v-loading="submitting">
    <h5 class="mb-4">Admission Requirements</h5>
    <label class="mb-3">Entrance Requirements</label>
    <ReUsableTextEditor v-model="entranceRequirements"></ReUsableTextEditor>

    <label class="mt-5 mb-3">Additional Requirements & Considerations</label>
    <ReUsableTextEditor v-model="additionalRequirements"></ReUsableTextEditor>

    <div
      style="display: flex; align-items: center"
      class="mt-4"
    >
      <el-button
        type="primary"
        @click="saveChanges"
      >Save Changes</el-button>
    </div>
  </div>
</template>
<script>
import ReUsableTextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
export default {
  props: {
    programmeId: { type: String, required: true },
    entranceHtmlPathURL: { required: true },
    additionalHtmlPathURL: { required: true },
  },
  components: {
    ReUsableTextEditor,
  },
  data() {
    return {
      submitting: false,
      additionalRequirements: "",
      entranceRequirements: "",
      entranceRequirementsHtmlPathURL: "",
      additionalRequirementsHtmlPathURL: "",
    };
  },

  watch: {
    entranceHtmlPathURL: {
      handler() {
        this.entranceText();
      },
    },
    additionalHtmlPathURL: {
      handler() {
        this.additionalText();
      },
    },
  },
  methods: {
    async entranceText() {
      try {
        if (this.entranceHtmlPathURL === null) {
          return false;
        } else {
          this.submitting = true;
          let request = await this.$http.get(`${this.entranceHtmlPathURL}`, {
            headers: {
              Authorization: "",
              contentType: "text/html",
            },
          });
          this.entranceRequirements = request.data;
        }
      } catch (error) {
        if (error.message == "NetworkError") {
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async additionalText() {
      try {
        if (this.additionalHtmlPathURL === null) {
          return false;
        } else {
          this.submitting = true;
          let request = await this.$http.get(`${this.additionalHtmlPathURL}`, {
            headers: {
              Authorization: "",
              contentType: "text/html",
            },
          });
          this.additionalRequirements = request.data;
        }
      } catch (error) {
        if (error.message == "NetworkError") {
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    // request for upload url
    async saveChanges() {
      if (!this.entranceRequirements) {
        this.showWarningMessage(
          "Entrance Requirements Missing",
          "Please Enter some Entrance Requirements for the programme"
        );
        return false;
      }

      if (!this.additionalRequirements) {
        this.showWarningMessage(
          "Additional Requirements Missing",
          "Please enter some Additional requirements for the programme."
        );
        return false;
      }
      // request for upload urls
      // const requirements = [this.additionalRequirements, this.entranceRequirements]
      try {
        this.submitting = true;
        let request = await this.$http.post(
          `website/request-for-html-content-upload-url`,
          {
            reason: "COURSE_DETAILS_HTML",
            quantity: 2,
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED"
        ) {
          const uploadUrls = request.data.upload_url;
          this.uploadResourcesToAWS(uploadUrls);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.submitting = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        if (error.response) {
          if (
            error.response.data.message ===
            "FILE SIZE SHOULD NOT BE MORE THAN 5MB"
          ) {
            return this.showWarningMessage(
              "File too Big",
              "The file can not be more than 5Mbs"
            );
          }
        }
        this.submitting = false;
        this.showFailedMessage(
          error
          // "Upload Requirements",
          // "Unable to Upload Requirements now, Please try again"
        );
      }
    },

    // ___________________________________________________ uploading files
    async uploadResourcesToAWS(uploadUrls) {
      this.submitting = true;
      const requirements = [
        this.additionalRequirements,
        this.entranceRequirements,
      ];
      try {
        // requirements.map((requirement) => {
        // for (var requirement in requirements) {
        for (
          var requirement = 0;
          requirement < requirements.length;
          requirement++
        ) {
          // requirement = 0 is , this.entranceRequirements
          if (requirement === 0) {
            let url = uploadUrls[0].url;
            let fields = uploadUrls[0].fields;
            const formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", this.entranceRequirements);
            this.submitting = true;

            let request = await this.$http.post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: " ",
              },
              onUploadProgress: (progressEvent) => console.log(progressEvent),
            });

            if (request.status == 204) {
              this.entranceRequirementsHtmlPathURL = request.headers.location;
            } else throw "UNEXPECTED_RESPONSE";
          }

          if (requirement === 1) {
            let url = uploadUrls[1].url;
            let fields = uploadUrls[1].fields;
            const formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => {
              formData.append(key, value);
            });
            formData.append("file", this.additionalRequirements);
            this.submitting = true;

            let request = await this.$http.post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: " ",
              },
              onUploadProgress: (progressEvent) => console.log(progressEvent),
            });

            if (request.status == 204) {
              this.additionalRequirementsHtmlPathURL = request.headers.location;
            } else throw "UNEXPECTED_RESPONSE";
          }
        }
        this.onAWSResourceUploaded(
          this.entranceRequirementsHtmlPathURL,
          this.additionalRequirementsHtmlPathURL
        );
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.submitting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.submitting = false;
        this.showFailedMessage(
          error
          // "Upload Failed",
          // "Unable to Upload Resource Now, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },

    async onAWSResourceUploaded(
      entranceRequirementsHtmlPathURL,
      additionalRequirementsHtmlPathURL
    ) {
      try {
        this.submitting = true;
        let request = await this.$http.patch(
          `website/courses/update-details/${this.programmeId}`,
          {
            entrance_requirements_html_path_url: decodeURIComponent(
              entranceRequirementsHtmlPathURL
            ),
            additional_requirements_html_path_url: decodeURIComponent(
              additionalRequirementsHtmlPathURL
            ),
          }
        );
        if (
          request.data.success &&
          request.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
        ) {
          this.showSuccessMessage(
            "Changes saved",
            "The changes were successfully saved"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.response.data.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style scoped></style>
