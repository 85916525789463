<template>
  <div v-loading="submitting">
    <h5 class="mb-4">OverView</h5>
    <label for="">Course Length</label>
    <el-input
      placeholder="Please input"
      v-model="courseLength"
    ></el-input>

    <label
      for=""
      class="mt-4"
    > Delivery Options </label> <br />
    <el-radio-group
      v-model="deliveryOptions"
      class=""
    >
      <el-radio label="ONLINE">Online / Virtual</el-radio>
      <br />
      <el-radio label="PHYSICAL">Physical Only</el-radio> <br />
      <el-radio label="BLENDED">Online & Physical</el-radio>
      <br />
    </el-radio-group>
    <br />
    <label
      for=""
      class="mt-4"
    >Language of Sturdy</label>
    <el-input
      placeholder="Please input"
      v-model="languageOfSturdy"
    ></el-input>

    <label
      for=""
      class="mt-4"
    >Collaborations</label>
    <el-input
      placeholder="Please input"
      v-model="collaborations"
    ></el-input>
    <div
      style="display: flex; align-items: center"
      class="mt-4"
    >
      <el-button
        v-if="!submitting"
        type="primary"
        @click="saveChanges"
      >Save Changes</el-button>
      <ScaleOut
        v-else
        :background="'#164B70'"
        class="ml-4"
      />
    </div>
  </div>
</template>
<script>
import ScaleOut from "@/components/scale-out-component.vue";
export default {
  props: {
    programmeId: { type: String, required: true },
    course_length: {
      required: true,
    },
    delivery_options: {
      required: true,
    },
    language_of_study: {
      required: true,
    },
    course_collaborations: {
      required: true,
    },
  },
  components: { ScaleOut },
  data() {
    return {
      submitting: false,
      courseLength: "",
      deliveryOptions: "online-or-virtual",
      languageOfSturdy: "",
      collaborations: "",
    };
  },

  watch: {
    course_length: {
      handler() {
        this.courseLength = this.course_length;
      },
    },
    delivery_options: {
      handler() {
        this.deliveryOptions = this.delivery_options;
      },
    },
    language_of_study: {
      handler() {
        this.languageOfSturdy = this.language_of_study;
      },
    },
    course_collaborations: {
      handler() {
        this.collaborations = this.course_collaborations;
      },
    },
  },

  methods: {
    validateAndShowErrors() {
      this.isValidated = false;

      if (!this.courseLength) {
        this.showWarningMessage(
          "Missing Course Length",
          "Please Enter Course Length for the programme"
        );
        return false;
      }

      if (!this.deliveryOptions) {
        this.showWarningMessage(
          "Unselected Delivery Options",
          "Please Select Delivery Options for the programme."
        );
        return false;
      }

      if (!this.languageOfSturdy) {
        this.showWarningMessage(
          "Missing Language Of Sturdy",
          "Please enter the Language Of Sturdy for the programme."
        );
        return false;
      }

      if (!this.collaborations) {
        this.showWarningMessage(
          "Missing Collaborations",
          "Please enter Collaborations for the programme."
        );
        return false;
      }

      this.isValidated = true;
      return this.isValidated;
    },
    async saveChanges() {
      if (this.validateAndShowErrors() == false) {
        return;
      }
      try {
        this.submitting = true;
        let response = await this.$http.patch(
          `website/courses/update-details/${this.programmeId}`,
          {
            // image_path_url: this.img,
            // start_date: this.startDate,
            // description: this.description,
            course_length: this.courseLength,
            delivery_options: this.deliveryOptions,
            language_of_study: this.languageOfSturdy,
            collaborations: this.collaborations,
            // entrance_requirements_html_path_url: this.entranceRequirements,
            // additional_requirements_html_path_url: this.additionalRequirements,
            // what_you_will_learn_html_path_url: "",
            // career_opportunities_html_path_url: this.careerOpportunities,
            // tuition_and_fees_attachment_path_url: "",
          }
        );
        if (
          response.data.success &&
          response.data.message == "COURSE DETAILS SUCCESSFULLY SAVED"
        ) {
          this.$emit("fetchSingleProgramme");
          return this.showSuccessMessage(
            "Changes saved",
            "The changes were successfully saved"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.response.data.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occured, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to Save Changes",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
<style scoped></style>
