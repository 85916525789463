<template>
  <div
    v-loading="isFetching"
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <div
      class="container"
      style="padding-top: 30px"
    >
      <BreadCrumbComponent :pageTitles="['Programmes', 'Programme Details']" />
    </div>
    <section>
      <article class="mt-4">
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                ">
                <strong>{{ programmeName ? programmeName : "..." }}</strong><br />
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-md-6">
              <div style="margin-top: 15px;">
                <el-input
                  placeholder="Search by Title..."
                  v-model.trim="search"
                  class="input-with-select"
                >
                </el-input>
              </div>
            </div>
          </div> -->
          <hr />
        </div>
      </article>
    </section>

    <section v-loading="loading">
      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING PROGRAMME DETAILS FROM SERVER--->
      <!-- <article v-show="isFetching && !isLoadingError" class="">
        <div class="container">
          <div class="programs">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article> -->

      <!--- SHOW THIS ARTICLE WHEN PROGRAMS ARE FETCHED FROM THE SERVER --->
      <!-- <article v-if="!isFetching && !isLoadingError"> -->
      <article>
        <div class="container">
          <template>
            <div>
              <!-- <el-radio-group v-model="tabPosition" style="margin-bottom: 30px">
                <el-radio-button label="Description"
                  >Description</el-radio-button
                >
                <el-radio-button label="OverView">OverView</el-radio-button>
                <el-radio-button label="Admission Requirements"
                  >Admission Requirements</el-radio-button
                >
                <el-radio-button label="What you will Learn"
                  >What you will Learn</el-radio-button
                >
                <el-radio-button label="Career Opportunities"
                  >Career Opportunities</el-radio-button
                >
                <el-radio-button label="Course Modules"
                  >Course Modules</el-radio-button
                >
                <el-radio-button label="Tuition & Fees"
                  >Tuition & Fees</el-radio-button
                >
              </el-radio-group>

              <el-tabs :tab-position="tabPosition" style="height: 200px">
                <el-tab-pane label="User">User</el-tab-pane>
                <el-tab-pane label="Config">Config</el-tab-pane>
                <el-tab-pane label="Role">Role</el-tab-pane>
                <el-tab-pane label="Task">Task</el-tab-pane>
              </el-tabs> -->
              <el-tabs type="border-card">
                <el-tab-pane label="Description">
                  <DescriptionComponent
                    :programmeId="programmeId"
                    :image_description_url="image_description_url"
                    :course_description="description"
                    :course_start_date="start_date"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>

                <el-tab-pane label="OverView">
                  <OverViewComponent
                    :programmeId="programmeId"
                    :course_length="course_length"
                    :delivery_options="delivery_options"
                    :language_of_study="language_of_study"
                    :course_collaborations="collaborations"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>

                <el-tab-pane label="Admission Requirements">
                  <AdmissionRequirementsComponent
                    :programmeId="programmeId"
                    :entranceHtmlPathURL="entranceRequirementsHtmlPathURL"
                    :additionalHtmlPathURL="additionalRequirementsHtmlPathURL"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>

                <el-tab-pane label="What you will Learn">
                  <WhatYouWillLearnComponent
                    :programmeId="programmeId"
                    :what_you_will_learn_html_path_url="what_you_will_learn_html_path_url"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>

                <el-tab-pane label="Career Opportunities">
                  <CareerOpportunitiesComponent
                    :programmeId="programmeId"
                    :career_opportunities_html_path_url="career_opportunities_html_path_url"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>

                <!-- <el-tab-pane label="Course Modules">
                  <CourseModulesComponent
                    :programmeId="programmeId"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane> -->

                <el-tab-pane label="Tuition & Fees">
                  <TuitionAndFeesComponent
                    :programmeId="programmeId"
                    :tuition_and_fees_attachment_path_url="tuition_and_fees_attachment_path_url"
                    @fetchSingleProgramme="fetchSingleProgramme"
                  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </template>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import DescriptionComponent from "../components/programme-details/description-component.vue";
import OverViewComponent from "../components/programme-details/overview-component.vue";
import AdmissionRequirementsComponent from "../components/programme-details/admission-requirements-component.vue";
import WhatYouWillLearnComponent from "../components/programme-details/what-you-will-learn-component.vue";
import CareerOpportunitiesComponent from "../components/programme-details/career-opportunities-component.vue";
// import CourseModulesComponent from "../components/programme-details/course-modules-component.vue";
import TuitionAndFeesComponent from "../components/programme-details/tuition-and-fees-component.vue";

export default {
  components: {
    BreadCrumbComponent,
    DescriptionComponent,
    OverViewComponent,
    AdmissionRequirementsComponent,
    WhatYouWillLearnComponent,
    CareerOpportunitiesComponent,
    // CourseModulesComponent,
    TuitionAndFeesComponent,
  },

  data() {
    return {
      isFetching: true,
      isLoadingError: false,
      loading: false,
      loadingError: false,
      programmeId: this.$route.params.programmeId,
      programmeName: this.$route.params.programmeName,
      image_description_url: "",
      description: "",
      start_date: "",
      course_length: "",
      delivery_options: "",
      language_of_study: "",
      collaborations: "",
      entranceRequirementsHtmlPathURL: "",
      additionalRequirementsHtmlPathURL: "",
      what_you_will_learn_html_path_url: "",
      career_opportunities_html_path_url: "",
      tuition_and_fees_attachment_path_url: "",
    };
  },

  mounted() {
    this.fetchSingleProgramme();
  },

  methods: {
    async fetchSingleProgramme() {
      try {
        this.isFetching = true;
        this.isLoadingError = false;
        let request = await this.$http.get(
          `website/courses/${this.$route.params.programmeId}`
        );
        if (
          request.data.success &&
          request.data.message == "COURSE FETCHED SUCCESSFULLY"
        ) {
          this.programme = request.data.course;
          this.programmeName = request.data.course.title;
          if (request.data.course.website_program_details) {
            this.image_description_url =
              request.data.course.website_program_details.image_path_url;

            this.description =
              request.data.course.website_program_details.description;

            this.start_date =
              request.data.course.website_program_details.start_date;

            this.course_length =
              request.data.course.website_program_details.course_length;
            this.delivery_options =
              request.data.course.website_program_details.delivery_options;
            this.language_of_study =
              request.data.course.website_program_details.language_of_study;
            this.collaborations =
              request.data.course.website_program_details.collaborations;

            this.entranceRequirementsHtmlPathURL =
              request.data.course.website_program_details.entrance_requirements_html_path_url;
            this.additionalRequirementsHtmlPathURL =
              request.data.course.website_program_details.additional_requirements_html_path_url;

            this.what_you_will_learn_html_path_url =
              request.data.course.website_program_details.what_you_will_learn_html_path_url;
            this.career_opportunities_html_path_url =
              request.data.course.website_program_details.career_opportunities_html_path_url;
            this.tuition_and_fees_attachment_path_url =
              request.data.course.website_program_details.tuition_and_fees_attachment_path_url;
          }
          this.isLoadingError = false;
          this.isFetching = false;
        } else {
          this.$rollbar.warning(
            "ADMIN WEBSITE FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isFetching = false;
        this.isLoadingError = true;
        return this.showFailedMessage(
          "Operation Failed",
          "Unable to load Programme Details"
        );
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.programs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.programs > div {
  background-color: white;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.programs > div:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
}
</style>
